<template>
  <div class="commodity">
    <el-card>
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="专题名称：">
          <el-input
            v-model="searchForm.search_input"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="searchForm.status" placeholder="请选择">
            <el-option
              v-for="item in status_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click="add_new_item"
        style="margin-bottom: 20px"
      >
        <i class="el-icon-plus"></i> 添加
      </el-button>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column fixed prop="id" label="ID" width="70">
        </el-table-column>
        <el-table-column prop="topic_name" label="专题名称"> </el-table-column>
        <el-table-column prop="remark" label="专题描述"> </el-table-column>
        <el-table-column prop="item_num" label="有效商品"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span
              :style="
                'color:' + (scope.row.status == 1 ? '#67C23A' : '#F56C6C') + ';'
              "
              >{{ getStatusToDetail(scope.row.status) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="editDetail(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-popover
              v-if="scope.row.status == 1"
              style="margin: 0 10px"
              placement="top"
              width="160"
              title="确定停用？"
              v-model="scope.row.visible"
            >
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="scope.row.visible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="stop_use(scope)"
                  >确定</el-button
                >
              </div>
              <el-button
                @click="stop_table_row(scope)"
                slot="reference"
                type="text"
                size="small"
                >停用</el-button
              >
            </el-popover>
            <el-popover
              v-if="scope.row.status == 2"
              style="margin: 0 10px"
              placement="top"
              width="160"
              title="确定启用？"
              v-model="scope.row.visible"
            >
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="scope.row.visible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="start_use(scope)"
                  >确定</el-button
                >
              </div>
              <el-button
                @click="stop_table_row(scope)"
                slot="reference"
                type="text"
                size="small"
                >启用</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 15px; text-align: center"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-sizes="[20, 30, 40, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      @close="clearDialogOldData('ruleForm')"
      width="40%"
    >
      <el-form
        ref="form"
        :inline="true"
        label-position="right"
        label-width="120px"
        :model="ruleForm"
        :rules="rules"
      >
        <el-row>
          <el-form-item label="专题名称：" prop="topic_name">
            <el-input
              v-model="ruleForm.topic_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="专题描述：" prop="remark">
            <el-input v-model="ruleForm.remark" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="更新频率：" prop="update_frequency">
            <el-input
              v-model="ruleForm.update_frequency"
              type="text"
              placeholder="请输入"
              style="width: 100px"
            ></el-input>
            <div class="tb_pinlv_tishi">分钟/次</div>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('dialogVisible')">取消</el-button>
        <el-button type="primary" @click="onSubmit('ruleForm')">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as api from "@/config/api";
import ElmUpLoad from "@/components/elmUpLoad/index.vue";
export default {
  components: {
    ElmUpLoad,
  },
  data() {
    const checkFloatNumber = (rule, value, callback, errorMsg) => {
      const r = /^[0-9]([0-9]+)?$/;
      if (value && !r.test(value)) {
        callback(new Error(errorMsg));
      } else {
        callback();
      }
    };
    return {
      // 弹出form
      ruleForm: {
        topic_name: "",
        remark: "",
        id: "",
        update_frequency: "",
        loading: false,
        // 错误提示消息
        errMessage: "",
      },
      // 弹出规则
      rules: {
        topic_name: [
          { required: true, message: "请输入专题名称", trigger: "blur" },
        ],
        // remark: [
        //   { required: true, message: "请输入主题描述", trigger: "blur" },
        // ],
        update_frequency: [
          // { required: true, message: "请输入更新频率", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value == "") {
                callback();
                return;
              }
              checkFloatNumber(rule, value, callback, "请输入正确更新频率");
            },
          },
        ],
      },
      searchForm: {
        // 顶部搜索值
        search_input: "",
        // 状态选择
        status: undefined,
      },
      // 状态列表
      status_list: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 2,
          label: "停用",
        },
      ],
      // 表格数据
      tableData: [],
      // 弹出显示
      dialogVisible: false,
      // 加载中
      loading: false,
      page: 1,
      pagesize: 20,
      total: undefined,
      // 彈出快的title
      dialogTitle: "",
    };
  },
  created() {
    this.getListData();
  },
  mounted() {},
  methods: {
    stop_table_row(e) {
      setTimeout(() => {
        this.tableData[e.$index].visible = true;
      }, 0);
    },
    // 关闭弹窗 传key
    closeDialog(key) {
      this[key] = false;
    },
    // 打开弹窗 传key
    openDialog(key) {
      this[key] = true;
    },
    //提交  key属于那个formData
    async onSubmit(key) {
      const v = await this.$refs.form.validate();
      if (!v) {
        return;
      }
      this[key].loading = true;
      if (key === "ruleForm") {
        let { topic_name, remark, id, update_frequency } = this[key];
        if (!id) {
          // 新增
          api.addCommoditySpecialItem(
            {
              topic_name,
              remark,
              update_frequency: update_frequency,
            },
            (res) => {
              this.$message({
                message: "新增成功！",
                type: "success",
              });
              this[key].loading = false;
              this.dialogVisible = false;
              this.getListData();
            },
            (err) => {
              this[key].loading = false;
              this.$message({
                message: "新增失败！",
                type: "success",
              });
            }
          );
        } else {
          // 编辑
          api.updateCommoditySpecialItem(
            {
              topic_name,
              remark,
              id,
              update_frequency: update_frequency,
            },
            (res) => {
              this.$message({
                message: "编辑成功！",
                type: "success",
              });
              this[key].loading = false;
              this.dialogVisible = false;
              this.getListData();
            },
            (err) => {
              this[key].loading = false;
              this.$message({
                message: "编辑失败！",
                type: "success",
              });
            }
          );
        }
      }
    },
    // 获取数据列表
    getListData() {
      let param = {
        topic_name: this.searchForm.search_input,
        status: this.searchForm.status,
      };
      this.loading = true;
      param.page = this.page;
      param.pagesize = this.pagesize;
      api.getCommoditySpecialList(
        param,
        (res) => {
          this.loading = false;
          if (!res.data || !res.data.list) {
            this.total = 0;
            this.tableData = [];
            return;
          }
          let list = res.data.list;
          this.total = Number(res.data.count);
          list.forEach((item, value) => {
            item.visible = false;
          });
          this.tableData = res.data.list;
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    // 搜索
    seach() {
      this.page = 1;
      this.pagesize = 20;
      this.total = undefined;
      this.getListData();
    },
    // 重置
    reset() {
      this.searchForm.search_input = "";
      this.searchForm.status = undefined;
      this.page = 1;
      this.pagesize = 20;
      this.total = undefined;
      this.getListData();
    },

    // 查看详情
    editDetail(e) {
      let id = e.id;
      if (!id) {
        this.$message({
          message: "沒有id",
          type: "error",
        });
        return;
      }

      this.dialogTitle = "编辑";
      this.ruleForm.topic_name = e.topic_name;
      this.ruleForm.remark = e.remark;
      this.ruleForm.id = e.id;
      this.ruleForm.update_frequency = e.update_frequency;
      this.openDialog("dialogVisible");
    },
    // 停止使用
    stop_use(e) {
      let item = e.row;
      api.updateCommoditySpecialItemStatus(
        {
          id: item.id,
          status: 2,
        },
        (res) => {
          this.tableData[e.$index].status = 2;
          this.tableData[e.$index].visible = false;
          this.$message({
            message: "修改成功！",
            type: "success",
          });
        },
        (err) => {
          this.$message({
            message: "修改失败！",
            type: "success",
          });
          this.tableData[e.$index].visible = false;
        }
      );
    },
    // 启用
    start_use(e) {
      let item = e.row;
      api.updateCommoditySpecialItemStatus(
        {
          id: item.id,
          status: 1,
        },
        (res) => {
          this.tableData[e.$index].status = 1;
          this.tableData[e.$index].visible = false;
          this.$message({
            message: "修改成功！",
            type: "success",
          });
        },
        (err) => {
          this.$message({
            message: "修改失败！",
            type: "success",
          });
          this.tableData[e.$index].visible = false;
        }
      );
    },
    // 根据状态码显示对应的内容
    getStatusToDetail(status) {
      if (status === undefined) return "";
      if (status == 1) {
        return "启用";
      } else if (status == 2) {
        return "停用";
      } else {
        return "其他";
      }
    },
    handleSizeChange(e) {
      this.pagesize = e;
      this.getListData();
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getListData();
    },
    // 添加一个新的
    add_new_item() {
      this.dialogTitle = "新增";
      this.ruleForm.topic_name = "";
      this.ruleForm.remark = "";
      this.ruleForm.id = "";
      this.ruleForm.update_frequency = "";
      this.dialogVisible = true;
    },
    clearDialogOldData(key) {
      if (key === "ruleForm") {
        this[key] = {
          topic_name: "",
          remark: "",
          id: "",
          loading: false,
          // 错误提示消息
          errMessage: "",
          update_frequency: "",
        };
      }
    },
  },
};
</script>
<style lang="scss">
.commodity {
  padding: 0 20px;
  text-align: left;
}
.go_down_model {
  display: inline-block;
  margin-bottom: 10px;
}
.input_down_tishi {
  position: absolute;
  bottom: 10px;
  transform: translate(0, 100%);
  font-size: 12px;
  color: #999;
}
.tb_pinlv_tishi {
  position: absolute;
  top: 50%;
  transform: translate(100%, -50%);
  right: -5px;
  font-size: 12px;
}
</style>