<template>
  <div class="elmUpload">
    <input
      type="file"
      ref="myFile"
      :accept="accept"
      :disabled="disabled"
      single
      @change="change"
    />
  </div>
</template>
<script>
import * as Api from "@/config/api.js";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      value: "image/*",
    },
  },
  data() {
    return {
      normal_uploadFlie: "",
      url: "",
    };
  },
  created() {},
  methods: {
    change(e) {
      let file = e.target.files[0];
      this.upload(file);
    },
    upload(file) {
      if (!file) return;
      let param = new FormData(); //创建form对象
      param.append("file", file); //通过append向form对象添加数据
      Api.upLoadFile(
        param,
        true,
        (res) => {
          this.url = res.data.url;
          this.$emit("upload_success", { url: res.data.url });
        },
        (err) => {
          // 需要重新上传
          this.$refs.myFile.value = "";
          this.$emit("upload_err", err);
        }
      );
    },
    clearUrl() {
      this.$refs.myFile.value = "";
    },
  },
};
</script>
<style lang="scss">
.elmUpload {
  position: relative;
}
</style>